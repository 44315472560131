import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Allcarditems from "../Allcarditems";
import { Dropdown, Space } from "antd";
import { allProducts } from "../Providers/Api_Calls";
import { ApiEndPoints } from "../Providers/Api_endpoints";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

interface DropdownItem {
  key: string;
  label: string;
  onClick?: () => void;
  children?: DropdownItem[];
}

const items: DropdownItem[] = [
  {
    key: "1",
    label: "Sort by",
    children: [
      { key: "2", label: "Low To High" },
      { key: "3", label: "High To Low" },
      // { key: "4", label: "Most Selling" },
      // { key: "5", label: "Less Selling" },
    ],
  },
];

const Allmutton = () => {
  const [products, setProducts] = useState<any[]>([]);
  const [sortedProducts, setSortedProducts] = useState<any[]>(products);
  const [sortOrder, setSortOrder] = useState<string>("");

  // const getAllProduct = async () => {
  //   try {
  //     const response = await allProducts(ApiEndPoints("allProduct"));
  //     // console.log(response);
  //     setProducts(response.products);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };


  const getAllProduct = async () => {
    try {
      const firstResponse = await allProducts(ApiEndPoints("allProduct"),1 );
      const totalPages = firstResponse.totalPages;
      let allfetchProducts: any[] = [...firstResponse.products];

      // Fetch the remaining pages
      for (let page = 2; page <= totalPages; page++) {
        const response = await allProducts(ApiEndPoints("allProduct"),page);
        allfetchProducts = [...allfetchProducts, ...response.products];
      }
      const chickenProducts = allfetchProducts.filter(
        (product: any) => product.category === "mutton"
      );
      setProducts(chickenProducts);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  useEffect(() => {
    getAllProduct();
  }, []);

  useEffect(() => {
    if (!Array.isArray(products)) return;

    const muttonProducts = products.filter(
      (product) => product.category === "mutton"
    );

    let sorted = [...muttonProducts];
    if (sortOrder === "2") {
      sorted.sort((a, b) => a.price - b.price);
    } else if (sortOrder === "3") {
      sorted.sort((a, b) => b.price - a.price);
    }
    setSortedProducts(sorted);
  }, [products, sortOrder]);

  const handleSort = (key: string) => {
    setSortOrder(key);
  };

  return (
    <>
       <Helmet>
        <title>Buy Goat & Lamb Mutton Online - Fresh, Clean, & Halal | At Meat Market</title>
        <meta name="description" content="Order fresh, clean, and halal goat and lamb mutton online at Meat Market. Enjoy premium quality meat delivered to your doorstep, hassle-free!" />
        <meta name="keywords" content="fresh goat mutton, lamb meat delivery, goat meat online shopping, organic mutton, fresh halal meat, mutton home delivery, goat and lamb meat, halal meat market, order goat meat, lamb meat shop online, mutton delivery service, premium goat meat, fresh lamb mutton, goat and lamb delivery, buy halal meat online, tender goat mutton, fresh meat delivery, lamb mutton online shopping, halal goat meat delivery" />
      </Helmet>
   
    <section className="py-4">
      <Container>
        <p className="nav-all pb-3">
          <Link to="/">Home</Link> {">"} <span>Mutton</span>
        </p>
        <div className="d-flex justify-content-between align-items-center allitems-nav mb-3">
          <h2>
            Mutton <span>{sortedProducts.length} Varieties</span>
          </h2>
          <Dropdown
            menu={{
              items:
                items[0].children?.map((item) => ({
                  key: item.key,
                  label: item.label,
                  onClick: () => handleSort(item.key),
                })) || [],
            }}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                Sort By
                <i className="fi fi-sr-settings-sliders"></i>
              </Space>
            </a>
          </Dropdown>
        </div>
        <div className="row ">
          {sortedProducts.length ? (
            sortedProducts.map((product) => (
              <Allcarditems key={product._id} product={product} />
            ))
          ) : (
            <h2 className="text-center">Loading ...!</h2>
          )}
        </div>
      </Container>
    </section>
    </>
  );
};

export default Allmutton;
