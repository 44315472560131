import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Allcarditems from "../Allcarditems";
import { Dropdown, MenuProps, Space } from "antd";
import { allProducts } from "../Providers/Api_Calls";
import { ApiEndPoints } from "../Providers/Api_endpoints";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

interface DropdownItem {
  key: string;
  label: string;
  onClick?: () => void;
  children?: DropdownItem[];
}

const items: DropdownItem[] = [
  {
    key: "1",
    label: "Sort by",
    children: [
      { key: "2", label: "Low To High" },
      { key: "3", label: "High To Low" },
    ],
  },
];

const AllChicken = () => {
  const [products, setProducts] = useState<any[]>([]);
  const [sortedProducts, setSortedProducts] = useState<any[]>(products);
  const [sortOrder, setSortOrder] = useState<string>("");

  // const getAllProduct = async () => {
  //   try {
  //     const response = await allProducts(ApiEndPoints("allProduct"));
  //     setProducts(response.products);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getAllProduct = async () => {
    try {
      const firstResponse = await allProducts(ApiEndPoints("allProduct"),1 );
      const totalPages = firstResponse.totalPages;
      let allfetchProducts: any[] = [...firstResponse.products];

      // Fetch the remaining pages
      for (let page = 2; page <= totalPages; page++) {
        const response = await allProducts(ApiEndPoints("allProduct"),page);
        allfetchProducts = [...allfetchProducts, ...response.products];
      }
      const chickenProducts = allfetchProducts.filter(
        (product: any) => product.category === "chicken"
      );
      setProducts(chickenProducts);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  useEffect(() => {
    getAllProduct();
  }, []);

  useEffect(() => {
    const chickenProducts = products.filter(
      (product) => product.category === "chicken"
    );

    let sorted = [...chickenProducts];
    if (sortOrder === "2") {
      sorted.sort((a, b) => a.price - b.price);
    } else if (sortOrder === "3") {
      sorted.sort((a, b) => b.price - a.price);
    }
    setSortedProducts(sorted);
  }, [products, sortOrder]);

  const handleSort = (key: string) => {
    setSortOrder(key);
  };

  return (
    <>
      <Helmet>
        <title>Fresh Chicken Online - Best Price & Quality Guaranteed | Order Now</title>
        <meta name="description" content="Buy fresh chicken online at unbeatable prices. Guaranteed quality, hygienic packing, and fast delivery to your home. Order now for delicious meals!" />
        <meta name="keywords" content="fresh chicken online, buy fresh chicken, quality chicken delivery, hygienic chicken packing, fast chicken delivery, affordable chicken prices, tender chicken online, fresh meat delivery, premium quality chicken, organic chicken online, chicken home delivery, best chicken prices, chicken delivery near me, fresh chicken marketplace, online chicken store" />
      </Helmet>
    <section className="py-4">
      <Container>
        <p className="nav-all pb-3">
          <Link to="/">Home</Link> {">"} <span>Chicken</span>
        </p>
        <div className="d-flex justify-content-between align-items-center allitems-nav mb-3">
          <h2>
            Chicken <span>{sortedProducts.length} Varieties</span>
          </h2>
          <Dropdown
            menu={{
              items:
                items[0].children?.map((item) => ({
                  key: item.key,
                  label: item.label,
                  onClick: () => handleSort(item.key),
                })) || [],
            }}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                Sort By
                <i className="fi fi-sr-settings-sliders"></i>
              </Space>
            </a>
          </Dropdown>
        </div>
        <div className="row">
          {sortedProducts.length ? (
            sortedProducts.map((product) => (
              <Allcarditems key={product._id} product={product} />
            ))
          ) : (
            <h2 className="text-center"> Loading ...!</h2>
          )}
        </div>
      </Container>
    </section>
    </>
  );
};

export default AllChicken;
